import React from "react";
import { graphql } from "gatsby";
import Layout from "../../components/Layout";
import SEO from "../../components/seo";
import { Container, Row, Col } from "react-bootstrap";
import TagCard from "../../components/TemplateTag/TagCard";

export const pageQuery = graphql`
  query {
    allTags: allMarkdownRemark(
      filter: { fileAbsolutePath: { regex: "//content/data/design//" } }
    ) {
      group(field: frontmatter___tags) {
        fieldValue
        totalCount
        edges {
          node {
            fields {
              slug
            }
            frontmatter {
              title
            }
          }
        }
      }
    }
  }
`;

const menu = "design";
const section = "tags";

const tags = ({ location, data }) => {
  return (
    <Layout location={location}>
      <SEO title={"Tags"} />

      <main role="main" style={{ marginTop: "56px" }}>
        <Container>
          <Row className="py-5">
            {data.allTags.group
              .sort((a, b) => b.totalCount - a.totalCount)
              .map(({ fieldValue, totalCount, edges }) => {
                const url = `/${menu}/${section}/${fieldValue.toLowerCase()}`;
                // console.log(fieldValue, totalCount, edges);
                return (
                  <Col key={fieldValue} sm={6} md={4} lg={3} className="mb-4">
                    <TagCard
                      url={url}
                      value={fieldValue}
                      totalCount={totalCount}
                      items={edges}
                    />
                  </Col>
                );
              })}
          </Row>
        </Container>
      </main>
    </Layout>
  );
};

export default tags;
